
import { IActivityRoomPost } from "@/core/data/activityRoomPost";
import { IModule } from "@/core/data/module";
import ModuleService from "@/core/services/ModuleService";
import UserRoleService from "@/core/services/UserRoleService";
import { useAuthStore } from "@/store/useAuthStore";
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import YouTube from "vue3-youtube";
import type { UploadProps, UploadUserFile } from "element-plus";
import Swal from "sweetalert2";
import { MenuComponent } from "@/assets/ts/components/index";
import ModulePostService from "@/core/services/ModulePostService";
import { IModulePost } from "@/core/data/modulePost";
import moment from "moment";

// interface IUploadFile {
//   file: any;
//   uid: number;
// }

// interface IFileAttached {
//   id: number;
//   fileName: string;
// }

export default defineComponent({
  name: "",
  components: { YouTube },
  props: { id: Number },
  setup() {
    const route = useRoute();
    const authStore = useAuthStore();
    const currentModule = ref<IModule>();
    const isTeacher = ref<boolean>(false);
    const youtubeRef = ref();
    const playIndex = ref(0);
    const formPostRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const uploadRef = ref();
    // const maxFileSize = ref(5 * 1024 * 1024); // Specify the maximum file size in bytes (e.g., 5MB)
    // const postFileTempList = ref<IUploadFile[]>([]);
    // const acceptedTypes = ref(".jpeg,.jpg,.png");
    // const fileList = ref<UploadUserFile[]>([]);
    // const dialogImageUrl = ref("");
    // const dialogVisible = ref(false);
    const posts = ref<Array<IModulePost>>([]);
    const moduleAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "modules/attachments/"
    );

    const postData = ref<IModulePost>({
      id: null,
      module: null,
      user: null,
      post: "",
      created_at: new Date(),
    });

    const postRules = computed(() => {
      return {
        post: [
          {
            max: 255,
            message: "Message must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
      };
    });

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    const setPlayIndex = (index) => {
      playIndex.value = index;
    };

    const initModule = () => {
      ModuleService.getModuleById(route.params.id).then((response) => {
        currentModule.value = response.data;
      });
    };

    const currentUser = computed(() => {
      return authStore.currentUser;
    });

    // const handlePictureCardPreview: UploadProps["onPreview"] = (uploadFile) => {
    //   dialogImageUrl.value = uploadFile.url!;
    //   dialogVisible.value = true;
    // };

    // const handleChange = (uploadFile) => {
    //   if (uploadFile) {
    //     const isAcceptedType = acceptedTypes.value
    //       .split(",")
    //       .some((type) => uploadFile.raw.name.toLowerCase().endsWith(type));
    //     const isSizeValid = uploadFile.size <= maxFileSize.value;

    //     if (!isAcceptedType) {
    //       Swal.fire({
    //         title: "Warning",
    //         text: "Only supports .jpeg, .jpg, .png file types.",
    //         icon: "warning",
    //         buttonsStyling: false,
    //         confirmButtonText: "ok",
    //         customClass: {
    //           confirmButton: "btn btn-primary",
    //         },
    //       });
    //     }

    //     if (!isSizeValid) {
    //       Swal.fire({
    //         title: "Warning",
    //         text:
    //           "Max file size is " + maxFileSize.value / (1024 * 1024) + "MB",
    //         icon: "warning",
    //         buttonsStyling: false,
    //         confirmButtonText: "ok",
    //         customClass: {
    //           confirmButton: "btn btn-primary",
    //         },
    //       });
    //     }
    //     if (isAcceptedType && isSizeValid) {
    //       let reader = new FileReader();
    //       reader.readAsDataURL(uploadFile.raw);
    //       reader.onload = (e) => {
    //         postFileTempList.value.push({
    //           file: uploadFile.raw,
    //           uid: uploadFile.uid,
    //         });
    //       };
    //     } else {
    //       uploadRef.value.handleRemove(uploadFile);
    //     }
    //   }
    // };

    // const handleRemove: UploadProps["onRemove"] = (uploadFile, uploadFiles) => {
    //   let index = postFileTempList.value.findIndex(
    //     (obj) => obj.uid === uploadFile.uid
    //   );
    //   if (index != -1) {
    //     postFileTempList.value.splice(index, 1);
    //   }
    // };

    const initPosts = () => {

      ModulePostService.getPostByModule(
        route.params.id
      ).then((response) => {
        posts.value = response.data;
      });

    };

    const onSubmitModulePost = () => {
      if (!formPostRef.value) {
        return;
      }
      formPostRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          var formData = new FormData();

          if (postData.value.id == null) {
            postData.value.module = currentModule.value!;
            formData.append("postData", JSON.stringify(postData.value));
            ModulePostService.createPost(formData).then((response) => {
              postData.value.post = "";
              loading.value = false;
              initPosts();
              Swal.fire({
                text: "Save complete",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            })
              .catch(({ response }) => {
                postData.value.post = "";
                loading.value = false;
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          }
        }
      });
    };

    const deletePost = (post) => {
      Swal.fire({
        title: `Would you like to delete this post?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          ModulePostService.deletePost(post?.id)
            .then((response) => {
              Swal.fire({
                text: "Delete successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              initPosts();
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Delete failed",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };

    const formatDateTime = (value) => {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
      } else return "-";
    };

    onMounted(() => {
      initModule();
      initPosts();
      getTeacher();
      // MenuComponent.reinitialization();
    });

    watch(
      () => route.params.id,
      (state, prevState) => {
        initModule();
      }
    );

    return {
      currentModule,
      currentUser,
      youtubeRef,
      playIndex,
      isTeacher,
      postData,
      postRules,
      formPostRef,
      setPlayIndex,
      onSubmitModulePost,
      // acceptedTypes,
      loading,
      // fileList,
      // dialogImageUrl,
      // dialogVisible,
      // handlePictureCardPreview,
      // handleChange,
      // handleRemove,
      // postFileTempList,
      moduleAttachmentbaseURL,
      posts,
      formatDateTime,
      deletePost
    };
  },
});
