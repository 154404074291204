import { AxiosResponse } from "axios";
import ApiService from "./ApiService";

const API_URL = "module-posts";
class ModulePostService {
  public static getPostByModule(moduleId): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/search/by-module/" + moduleId);
  }
  public static createPost(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL, formData);
  }

  public static deletePost(id): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/" + id);
  }
}
export default ModulePostService;
